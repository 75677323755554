import { grayColor } from "assets/jss/material-dashboard-pro-react.jsx";

const gridSystemStyle = {
  title: {
    color: grayColor[2],
    textDecoration: "none"
  }
};

export default gridSystemStyle;
